import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { updateCollection } from "../api/v1/collections";
import { collectionQuery } from "./useCollection";
import { collectionsQuery } from "./useCollections";

const i18n = {
  success: "Collection updated successfully!",
  error: "Unable unable to update collection at this time.",
};

export const useCollectionUpdate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (id, payload) => {
    setFetching(true);

    updateCollection(id, payload)
      .then(async () => {
        await invalidateQueries([collectionsQuery(), collectionQuery(id)]);
        setFetching(false);
        setFetched(true);
        enqueueSnackbar(i18n.success, { variant: "success" });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error[0]
            : i18n.error;

        setFetching(false);
        setError(true);
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isFetching, isFetched, isError };
};
