import React, { useContext, useState } from "react";
import { Box, Button, Pagination } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useParams } from "react-router";
import { Header } from "./components/Header";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useCollection } from "../../hooks/useCollection";
import { CardListing } from "../../components/layout/CardListing";
import { useCollectionContents } from "../../hooks/useCollectionContents";
import ContentCard from "../../components/cards/ContentCard";
import ContentCreateModal from "../../components/modals/ContentCreateModal";
import { UserContext } from "../../contexts/UserContext";
import ContentModal from "../../components/modals/ContentModal";

const i18n = {
  add: "add content",
  error:
    "Error loading collection... This collection either does not exist or is set to private.",
};

const Collection = () => {
  const [page, setPage] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1,
  );
  let { id, contentId } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const [zoomedContent, setZoomedContent] = useState(contentId);
  const { user: cookieUser, hasUser } = useContext(UserContext);

  const {
    data: collection,
    isFetched: isFetchedCollection,
    isError: isErrorCollection,
  } = useCollection(id);
  const { data: contents, isFetched: isFetchedContents } =
    useCollectionContents({
      id,
      page,
      enabled: isFetchedCollection && !isErrorCollection,
    });

  const onZoomIn = (content) => {
    // Update direct path
    let path = `/collections/${id}/contents/${content.id}/`;
    path = page > 1 ? path + `?page=${page}` : path;
    navigate(path, { replace: true });

    setZoomedContent(content.id);
  };

  const onZoomOut = () => {
    let path = `/collections/${id}/`;
    path = page > 1 ? path + `?page=${page}` : path;

    navigate(path, { replace: true });
    setZoomedContent(null);
  };

  const belongsToUser = () => {
    return hasUser() && collection && cookieUser.id === collection.data.user.id;
  };

  const renderContents = () => {
    if (!isFetchedContents) {
      return <ProgressLoader />;
    }

    return (
      <>
        <CardListing>
          {contents.data.data.map((content) => {
            return (
              <ContentCard
                onClick={() => onZoomIn(content)}
                key={`content-${content.id}`}
                content={content}
                collection={collection.data}
                page={page}
              />
            );
          })}
        </CardListing>
        {renderPagination()}
      </>
    );
  };

  const renderPagination = () => {
    const { total_pages } = contents.data;
    if (total_pages > 1) {
      return (
        <Pagination
          page={page}
          count={total_pages}
          onChange={(event, page) => {
            setPage(page);
            navigate(`?page=${page}`);
          }}
        />
      );
    }
  };

  if (!isFetchedCollection) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isErrorCollection) {
    return <Layout>{i18n.error}</Layout>;
  }

  return (
    <Layout>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
        <Header collection={collection.data} />
      </Box>
      {belongsToUser() && (
        <Button onClick={() => setIsOpen(true)}>
          <Add sx={{ mr: 1 }} />
          {i18n.add}
        </Button>
      )}
      <Box mt={2}>{renderContents()}</Box>

      {isOpen && (
        <ContentCreateModal
          collection={collection.data}
          page={page}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}

      {zoomedContent && (
        <ContentModal id={zoomedContent} onClose={onZoomOut} open={true} />
      )}
    </Layout>
  );
};

export default Collection;
